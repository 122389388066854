import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import { query, onSnapshot } from "firebase/firestore";
 
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

import '../../css/index.css';

class AllProjectPage extends Component {
    constructor(props) {
        super(props);
   
        this.state = {
          projects: [],
          loading: false,
        };
    }

    componentDidMount() {
        let projects = [];

        this.listener = onSnapshot(query(this.props.firebase.projects()), (snapshot) =>{
            snapshot.forEach((doc) => {
              if (!projects.map((project) => project.id).includes(doc.id)) {
                projects.push({ ...doc.data(), id: doc.id })
              }
            });
            this.setState({
              projects,
            });
        });
    }

    componentWillUnmount() {
      this.listener();
    }
   
    render() {
        const { projects, loading } = this.state;
  
        if (typeof projects === "undefined" || projects === null || loading) {
          return <div>{loading && <div>Loading ...</div>}</div>
        } else {
          return (
            <div>
              <h1> Projects </h1>
              {projects.map((project) => 
                <Link key={project.id} to={ROUTES.PROJECT+`/${project.id}`} >{project.name}</Link>
              )}
            </div>
          );
        }
    } 
}
export default withFirebase(AllProjectPage);
