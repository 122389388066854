import React, { Component } from 'react';
import { query, onSnapshot } from "firebase/firestore";

import { withFirebase } from '../Firebase';

// import Carousel from 'react-material-ui-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// var Carousel = require('react-responsive-carousel').Carousel;
// import { Paper, Button } from '@mui/material'

class App extends Component {
    constructor(props) {
      super(props);
 
      this.state = {
        loading: false,
        images: [],
      };
    }

    componentDidMount() {
      this.setState({ loading: true });

      let images = [];

      this.listener = onSnapshot(query(this.props.firebase.images()), (snapshot) =>{
        snapshot.forEach((doc) => 
          images.push({ ...doc.data(), id: doc.id }),
        );
        console.log(images);

        this.setState({
          images,
          loading: false,
        });
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      const { images } = this.state;

      return (
        <div style={{
                    maxHeight: '100%',
                    height: '100%',
                    aspectRatio: 1, 
                }}>
          <h1>Cramer Carpentry</h1> 
          <Carousel infiniteLoop autoPlay >
            {images.map(image => (
              <div key={image.url}>
                <img src={image.medium_url} alt=' ' style={{
                    maxWidth: '90%',
                    maxHeight: '50%',
                    aspectRatio: 1, 
                }}/>

              </div>
            ))}
          </Carousel>
        </div>
      );
    }
}

export default withFirebase(App);
