import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, collection } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import env from "react-dotenv";

const config = {
  apiKey: env.FIREBASE_APIKEY,
  authDomain: env.FIREBASE_AUTHDOMAIN,
  projectId: env.FIREBASE_PROJECTID,
  storageBucket: env.FIREBASE_STORAGEBUCKET,
  messagingSenderId: env.FIREBASE_MESSAGINGSENDERID,
  appId: env.FIREBASE_APPID,
  measurementId: env.FIREBASE_MEASUREMENTID
};

class Firebase {
  constructor() {
    initializeApp(config);

    // this.fieldValue = firestore.FieldValue;
 
    this.auth = getAuth();
    this.db = getFirestore();
    // this.Timestamp = firestore.Timestamp;
  }
 
  // *** Auth API ***
 
  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password);
 
  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password); 

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
 
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

 
  // *** User API ***
 
  // user = uid => this.db.doc(`users/${uid}`);
 
  // users = () => this.db.collection('users');

  image = iid => getDoc(doc(this.db,'images',iid));

  imageRef = iid => doc(this.db,'images',iid);
 
  images = () => collection(this.db,'images');

  project = pid => getDoc(doc(this.db,'projects',pid));

  projectRef = pid => doc(this.db,'projects',pid);
 
  projects = () => collection(this.db,'projects');
}


export default Firebase;
