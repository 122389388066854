import React, { Component } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

import { query, onSnapshot } from "firebase/firestore";
import { addDoc, getDocs, updateDoc, deleteDoc } from "firebase/firestore";

import { withFirebase } from '../Firebase';
import { withAuthentication } from '../Session'

import Popup from "reactjs-popup";

import { SignInPopUp } from '../SignIn';
import SignOutButton from '../SignOut';

import Resizer from "react-image-file-resizer";

class EditImagesBase extends Component {
    constructor(props) {
        super(props);

        this.style = props.style;
 
        this.state = {
          allImages: [],
          formFileName: '',
          uploadedFileName: '',
          editDisabled: true,
          message: '',
        };

        this.storage = getStorage();
        this.editImage  = this.editImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    componentDidMount() {
        let allImages = [];

        this.listener = onSnapshot(query(this.props.firebase.images()), (snapshot) =>{
            snapshot.forEach((doc) => {
              if (!allImages.map((img) => img.id).includes(doc.id)) {
                allImages.push({ ...doc.data(), id: doc.id })
              }
            });
            this.setState({
              allImages,
            });
        });
    }

    componentWillUnmount() {
      this.listener();
    }
    
    onFileChange = event => {
        this.setState({
          selectedFile: event.target.files[0],
          message: '',
          uploadedFileName: event.target.files[0].name,
        });
        event.target.value = null;
    };

    onFileNameChange = event => {
        this.setState({ uploadedFileName: event.target.value, message:'' });
    };
    
    // On file upload (click the upload button)
    onFileUpload = () => {
        const { uploadedFileName } = this.state;

        // Details of the uploaded file
        let fileName = this.state.selectedFile.name;
        const ext = fileName.substr(fileName.lastIndexOf('.')+1);
        let FORMAT = 'PNG';
        if (ext === 'jpg' || ext === 'jpeg') {
            FORMAT = 'JPEG';
        }
        if (uploadedFileName) {
            fileName = uploadedFileName;
        }
        
        addDoc(this.props.firebase.images(),{})
          .then(doc => {
              const iid = doc.id;

              // Add Medium Image
              Resizer.imageFileResizer(
                this.state.selectedFile,
                1000,
                1000,
                FORMAT,
                100,
                0,
                (uri) => {
                  console.log(uri);
                  uploadBytes(ref(this.storage, 'medium_images/'+iid+'.'+ext), uri).then((snapshot) => {
                      getDownloadURL(snapshot.ref)
                        .then((url) => {
                          updateDoc(this.props.firebase.imageRef(iid), {
                            medium_url: url,
                          });
                        });
                  });
                },
                "file",
              );

              // Add Medium Image
              Resizer.imageFileResizer(
                this.state.selectedFile,
                200,
                200,
                FORMAT,
                100,
                0,
                (uri) => {
                  console.log(uri);
                  uploadBytes(ref(this.storage, 'small_images/'+iid+'.'+ext), uri).then((snapshot) => {
                      getDownloadURL(snapshot.ref)
                        .then((url) => {
                          updateDoc(this.props.firebase.imageRef(iid), {
                            small_url: url,
                          });
                        });
                  });
                },
                "file",
              );

              // Add Image
              uploadBytes(ref(this.storage, 'images/'+iid+'.'+ext), this.state.selectedFile).then((snapshot) => {
                  getDownloadURL(snapshot.ref)
                    .then((url) => {
                      updateDoc(this.props.firebase.imageRef(iid), {
                        name: fileName,
                        storage_ref: iid+'.'+ext,
                        url: url,
                      });
                    });
                  this.setState({
                    selectedFile: null,
                    message: 'Upload Sucessful',
                    uploadedFileName: null,
                  });
              });
          });
    };

    onResizedFileUpload = () => {
        const { uploadedFileName } = this.state;
        let fileName = this.state.selectedFile.name;
        const ext = fileName.substr(fileName.lastIndexOf('.')+1);
        let FORMAT = 'PNG';
        if (ext === 'jpg' || ext === 'jpeg') {
            FORMAT = 'JPEG';
        }
        if (uploadedFileName) {
            fileName = uploadedFileName;
        }
        const iid = fileName.substr(0,fileName.lastIndexOf('.'));

        Resizer.imageFileResizer(
          this.state.selectedFile,
          1000,
          1000,
          FORMAT,
          100,
          0,
          (uri) => {
            console.log(uri);
            uploadBytes(ref(this.storage, 'medium_images/'+iid+'.'+ext), uri).then((snapshot) => {
                getDownloadURL(snapshot.ref)
                  .then((url) => {
                    updateDoc(this.props.firebase.imageRef(iid), {
                      medium_url: url,
                    });
                    this.setState({
                      selectedFile: null,
                      message: url,
                      medium_img: url,
                      uploadedFileName: null,
                    });
                  });
            });
          },
          "file",
        );

        Resizer.imageFileResizer(
          this.state.selectedFile,
          200,
          200,
          FORMAT,
          100,
          0,
          (uri) => {
            console.log(uri);
            uploadBytes(ref(this.storage, 'small_images/'+iid+'.'+ext), uri).then((snapshot) => {
                getDownloadURL(snapshot.ref)
                  .then((url) => {
                    updateDoc(this.props.firebase.imageRef(iid), {
                      small_url: url,
                    });
                    this.setState({
                      selectedFile: null,
                      message: url,
                      small_img: url,
                      uploadedFileName: null,
                    });
                  });
            });
          },
          "file",
        );
        
    };

    onNameChange = event => {
        this.setState({
          [event.target.name]: event.target.value,
          editDisabled: (event.target.value === '')
        });
    };
    
    editImage(iid, e) {
        const { formFileName } = this.state;
        console.log('EDIT_IMAGE:',iid,formFileName);
        updateDoc(this.props.firebase.imageRef(iid), {
            name:formFileName
        });

        e.preventDefault();
    }

    deleteImage(iid) {
        this.props.firebase.image(iid)
          .then((snapshot) => {
              const image = snapshot.data();
              deleteObject(ref(this.storage, 'small_images/'+image.storage_ref));
              deleteObject(ref(this.storage, 'medium_images/'+image.storage_ref));
              deleteObject(ref(this.storage, 'images/'+image.storage_ref));
              deleteDoc(this.props.firebase.imageRef(iid));
          });
        getDocs(query(this.props.firebase.projects())).then((docs) => {
            docs.forEach((doc) => {
                const project_images = doc.data().images;
                if (project_images.includes(iid)) {
                    project_images.splice(project_images.indexOf(iid),1)
                    updateDoc(this.props.firebase.projectRef(doc.id), {
                        images: project_images
                    });
                }
            });
        });
    }

    render() {
        const { allImages, formFileName, uploadedFileName } = this.state;

        return (
            <div>
              <h1> Edit Images </h1>
              <div style={{overflowY: 'scroll', height: 300, width: 500}}>
                {allImages.map( image => (
                  <div key={image.id} style={{height: 100}} >
                    <div style={{position: 'relative', left: 0, height: 100, width: 300, border: '2px solid black' }} >
                      <p> {image.name} </p>
                      <Popup trigger={<button> Edit </button>}
                        modal
                        closeOnEscape
                      >
                        {close => {
                            return (
                                <div className="sign-in">
                                  <form onSubmit={event => this.editImage(image.id, event)} >
                                    <input
                                      name="formFileName"
                                      value={formFileName}
                                      onChange={this.onNameChange}
                                      type="text"
                                      placeholder="file name"
                                    />
                                    <button disabled={this.state.editDisabled} type='submit'> Save </button>
                                  </form>
                                </div>
                        )}}
                      </Popup>
                      <button onClick={() => this.deleteImage(image.id)} > delete </button>
                    </div>
                    <img src={image.small_url} alt=' ' style={{position: 'relative', left: 300, top: -100, height: 100}} onClick={()=> window.open(image.url, "_blank")} />
                  </div>
                ))}
              </div>
              <h3> Upload Image </h3>
              <div>
              {
                this.state.selectedFile ?  (
                  <div>
                    <input 
                      name="onFileNameChange"
                      value={uploadedFileName}
                      onChange={this.onFileNameChange}
                      type="text"
                      placeholder="file name"
                    />
                    <button onClick={this.onFileUpload}> Upload </button>
                  </div>
                ) : ( 
                  <div>
                    <input type="file" onChange={this.onFileChange} accept='image/*'/>
                  </div>
                )
              }
              </div>
              <a href={this.state.message}>{this.state.message}</a>
            </div>
        );
    }
}
const EditImages = withFirebase(EditImagesBase);

class EditProjectBase extends Component {
    constructor(props) {
        super(props);
 
        this.pid = props.pid;
        this.state = {
          allImages: [],
          selectedImages: [],
          unselectedImages: [],
          authUser: null,
          message: '',
          loading: false,
        };

        this.storage = getStorage();
        this.saveImages = this.saveImages.bind(this);
    }

    componentDidMount() {
      this.setState({ loading: true });

      let allImages = [];

      this.listener = onSnapshot(query(this.props.firebase.images()), (snapshot) =>{
          snapshot.forEach((doc) => {
            if (!allImages.map((img) => img.id).includes(doc.id)) {
              allImages.push({ ...doc.data(), id: doc.id })
            }
          });

          this.props.firebase.project(this.pid).then((doc) =>{

              const project_images = doc.data().images;

              let selectedImages = [];
              let unselectedImages = [];

              allImages.forEach((img) => {
                  if (project_images.includes(img.id)) {
                      selectedImages.push(img);
                  } else {
                      unselectedImages.push(img);
                  }
              });

              this.setState({
                allImages,
                selectedImages,
                unselectedImages,
                loading: false,
              });
          });
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    addImage(iid) {
        console.log('addImage',iid);
        const { selectedImages, unselectedImages } = this.state;
        let iidx = 0;
        let idx = 0;
        unselectedImages.forEach((img) => {
            if (img.id === iid) {
                iidx = idx 
            }
            idx += 1;
        });
        selectedImages.push(unselectedImages[iidx]);
        unselectedImages.splice(iidx,1);
        this.setState({
          selectedImages: selectedImages,
          unselectedImages: unselectedImages,
          message: 'unsaved changes',
        });
    }

    removeImage(iid) {
        console.log('removeImage',iid);
        const { selectedImages, unselectedImages } = this.state;
        let iidx = 0;
        let idx = 0;
        selectedImages.forEach((img) => {
            if (img.id === iid) {
                iidx = idx 
            }
            idx += 1;
        });
        unselectedImages.push(selectedImages[iidx]);
        selectedImages.splice(iidx,1);
        this.setState({ selectedImages, unselectedImages });
    }

    moveImage(iid,direction) {
        const { selectedImages } = this.state;
        let iidx = 0;
        let idx = 0;
        selectedImages.forEach((img) => {
            if (img.id === iid) {
                iidx = idx 
            }
            idx += 1;
        });
        const nidx = iidx+direction;
        console.log('moveImage',iid,direction,nidx);
        if (nidx >= 0 && nidx < selectedImages.length) {
            const movingImage = selectedImages[iidx];
            selectedImages.splice(iidx,1);
            selectedImages.splice(nidx,0,movingImage);
            this.setState({
              selectedImages: selectedImages,
              message: 'unsaved changes',
            });
            console.log('Image moved');
        }
    }
    
    saveImages() {
        const { selectedImages } = this.state;
        updateDoc(this.props.firebase.projectRef(this.pid), {
            images: selectedImages.map(image => image.id)
        }).then(() => {
            this.setState({
              message: 'saved',
            });
        });   
    }

    render() {
        const { selectedImages, unselectedImages } = this.state;

        return (
            <div>
              <p>To change to another project, switch back to default selection first</p>
              <h3> Unselected Images </h3>
              <div style={{overflowY: 'scroll', height: 200, width: 500}}>
                {unselectedImages.map(image => (
                  <div key={image.id} style={{height: 100}} >
                    <div style={{position: 'relative', left: 0, height: 100, width: 300, border: '2px solid black' }} >
                      <p>{image.name}</p>
                      <button onClick={() => this.addImage(image.id)}> Select </button>
                    </div>
                    <img src={image.small_url} alt=' ' style={{position: 'relative', left: 300, top: -100, height: 100}} />
                  </div>
                ))}
              </div>
              <h3> Selected Images </h3>
              <div style={{overflowY: 'scroll', height: 200, width: 500}}>
                {selectedImages.map(image => (
                  <div key={image.id} style={{height: 100}} >
                    <div style={{position: 'relative', left: 0, height: 100, border: '2px solid black' }} >
                      <p>{image.name}</p>
                      <button onClick={() => this.removeImage(image.id)}> Deselect </button>
                      <button onClick={() => this.moveImage(image.id,-1)}> Move Up </button>
                      <button onClick={() => this.moveImage(image.id,+1)}> Move Down </button>
                    </div>
                    <img src={image.small_url} alt=' ' style={{position: 'relative', left: 300, top: -100, height: 100}} />
                  </div>
                ))}
              </div>
              <button onClick={this.saveImages}> Save </button>
              <p> {this.state.message} </p>
            </div>
        );
    }
}
const EditProject = withFirebase(EditProjectBase);

class EditAllProjectsBase extends Component {
    constructor(props) {
        super(props);
 
        this.state = {
          allProjects: [],
          selectedProjectPid: '',
          formProjectDescription: '',
          formProjectName: '',
          message: '',
        };

        this.storage = getStorage();
        this.handleChange  = this.handleChange.bind(this);

        this.newProject = this.newProject.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
    }

    componentDidMount() {
        let allProjects = [];

        this.listener = onSnapshot(query(this.props.firebase.projects()), (snapshot) =>{
            snapshot.forEach((doc) => {
              if (!allProjects.map((project) => project.id).includes(doc.id)) {
                allProjects.push({ ...doc.data(), id: doc.id })
              }
            });
            this.setState({
              allProjects,
            });
        });
    }

    componentWillUnmount() {
      this.listener();
    }

    handleChange(e) {
        this.setState({selectedProjectPid: e.target.value});
    }
                
    newProject(e) {
        addDoc(this.props.firebase.projects(),{ name: this.state.formProjectName,
                                                description: this.state.formProjectDescription,
                                                images: []}).then(() => {
            this.setState({
                formProjectName: '',
                formProjectDescription: '',
                message: 'New Project Created',
            });
        });
        e.preventDefault();
    }

    onNameChange(e) {
        this.setState({formProjectName: e.target.value});
    }

    onDescriptionChange(e) {
        this.setState({formProjectDescription: e.target.value});
    }

    render() {
        const { 
                allProjects, 
                selectedProjectPid,
                formProjectDescription,
                formProjectName
               } = this.state;

        const newProjectDisabled = formProjectName === ''

        return (
            <div>
              <h1> Edit Projects </h1>
              <select value={this.state.selectedProjectPid} onChange={this.handleChange} >
                    <option value=''>select project to edit</option>
                {allProjects.map((project) => (
                    <option key={project.id} value={project.id}>{project.name}</option>
                ))}
              </select>
              { selectedProjectPid && <EditProject pid={selectedProjectPid} /> }
              <h2> Create New Project </h2>
              <form onSubmit={this.newProject} >
                <input
                  name="formProjectName"
                  value={formProjectName}
                  onChange={this.onNameChange}
                  type="text"
                  placeholder="Project Name"
                />
                <input
                  name="formProjectDescription"
                  value={formProjectDescription}
                  onChange={this.onDescriptionChange}
                  type="text"
                  placeholder="Project Description"
                />
                <button disabled={newProjectDisabled} type='submit'> New Project </button>
              </form>
            </div>
        );
    }
}
const EditAllProjects = withFirebase(EditAllProjectsBase);

class AdminPage extends Component {
    constructor(props) {
      super(props);
 
      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        authUser
          ? this.setState({ authUser })
          : this.setState({ authUser: null });
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
        if (this.state.authUser) {
            return (
                <div>
                  <SignOutButton />
                  <EditImages />
                  <EditAllProjects />
                </div>
            );
        } else {
            return (
                <div>
                  <h1> Sign In </h1>
                  <SignInPopUp />
                </div>
            );
        }
    }
}


export default withAuthentication(AdminPage);
