import React, { Component } from 'react';

import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { withAuthentication } from '../Session'

import LandingPage from '../Landing';
import AdminPage from '../Admin';
import AllProjectPage from '../AllProjects';
import ProjectPage from '../Project';

import * as ROUTES from '../../constants/routes';

class App extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      authUser: null,
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  render() {
    return (
      <Router>
        <div>
          <Route exact path={ROUTES.LANDING} component={LandingPage} />
          <Route exact path={ROUTES.ADMIN} component={AdminPage} />

          <Route exact path={ROUTES.PROJECTS} component={AllProjectPage} />
          <Route path={ROUTES.PROJECT+"/:pid"} component={ProjectPage} />
        </div>
      </Router>
    );
  }
}

export default withAuthentication(App);
