import React, { Component } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { withFirebase } from '../Firebase';

import '../../css/index.css';

class ProjectPage extends Component {
    constructor(props) {
        super(props);
   
        this.pid = props.match.params.pid;

        this.state = {
          loading: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        let images = [];

        this.listener = this.props.firebase.project(this.pid).then((doc) =>{
            const project = doc.data(); 
            project.images.reduce( (chain,iid) => 
              chain.then(() => new Promise(resolve => {
                this.props.firebase.image(iid).then(doc => {
                    images.push({...doc.data(), id:iid});
                    resolve();
                  })
                  .catch(error => console.log(`ERROR: ${error}`));
                })),
              Promise.resolve())
              .then(() => {
                this.setState({
                  images,
                  project,
                  loading: false,
                });
              });
        });
    }

    componentWillUnmount() {
        this.listener();
    }
   
    render() {
        const { images, project, loading } = this.state;
  
        if (typeof images === "undefined" || images === null || loading) {
          return <div>{loading && <div>Loading ...</div>}</div>
        } else {
          return (
            <div>
              <div style={{
                          height: '70%',
                      }}>
                <Carousel infiniteLoop autoPlay >
                  {images.map(image => (
                    <div key={image.url}>
                      <img src={image.medium_url} alt=' ' />

                    </div>
                  ))}
                </Carousel>
              </div>
              <p>{project.description}</p>
            </div>
          );
        }
    } 
}
export default withFirebase(ProjectPage);
